import React, { useEffect, useState } from "react";
import {
  ListItemButton,
  Badge,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Tooltip,
  ListItemAvatar,
  Avatar,
} from "@mui/material";
import Group from "@mui/icons-material/Group";
import MoreHoriz from "@mui/icons-material/MoreHoriz";

import { NotificationsApi } from "@unity/components";
import { usePusher } from "../../services/PusherContext";

export default function GroupItem({
  group,
  currentChat,
  setCurrentChat,
  setCurrentGroup,
  setMenuOpen,
}) {
  const { pusher } = usePusher();

  const [count, setCount] = useState(0);

  useEffect(() => {
    const getCount = async () => {
      const res = await NotificationsApi.getCount("Group", group?._id, true);
      if (res && res.success) {
        setCount(res.data);
      } else {
        setCount(0);
      }
    };
    let channel;
    const channelConnect = async () => {
      channel = await pusher.subscribe(group?._id);
      channel.bind(`notification`, () => {
        getCount();
        window.dispatchEvent(new CustomEvent(`messageReceived`));
      });
    };
    getCount();
    channelConnect();
    return () => {
      channel.unbind("notification");
      pusher.unsubscribe(group?._id);
    };
  }, [group, pusher]);

  return (
    <ListItemButton
      onClick={() => {
        setCurrentChat(group);
      }}
      selected={currentChat?._id === group?._id}
      sx={{ overflow: "hidden", pt: "6px", pb: "6px" }}
    >
      <ListItemAvatar>
        <Avatar sx={{ width: 36, height: 36 }}>
          <Group />
        </Avatar>
      </ListItemAvatar>
      <Tooltip title={group?.name} placement="bottom-start">
        <Badge
          sx={{
            ".MuiBadge-badge": {
              minWidth: 14,
              height: 14,
              fontSize: "0.7rem",
              mt: 0.5,
            },
          }}
          color="success"
          badgeContent={count}
          invisible={!count}
        >
          <ListItemText primary={group?.name} sx={{ pt: 0.5 }} />
        </Badge>
      </Tooltip>
      <ListItemSecondaryAction>
        <IconButton
          sx={{ bgcolor: "#fafafa" }}
          onClick={(e) => {
            e.stopPropagation();
            setCurrentGroup(group);
            setMenuOpen(e.currentTarget);
          }}
        >
          <MoreHoriz />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItemButton>
  );
}
