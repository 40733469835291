import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Avatar,
  Badge,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import { getUserInitials } from "../utils/getUserInitials";
import { usePusher } from "../services/PusherContext";
import { NotificationsApi, useAuth } from "@unity/components";

export default function UserItem({
  checkOnline,
  user,
  getColor,
  currentChat,
  setCurrentChat,
}) {
  const color = useRef("");
  const { pusher } = usePusher();
  const { auth } = useAuth();
  const me = user?.uuid === auth.id;

  const [count, setCount] = useState(0);

  useEffect(() => {
    color.current = getColor();
  }, []);

  useEffect(() => {
    const getCount = async (channelName) => {
      const res = await NotificationsApi.getCount("Group", channelName, true);
      if (res && res.success) {
        setCount(res.data);
      } else {
        setCount(0);
      }
    };
    let channel;
    let channelName;
    const channelConnect = async (channelName) => {
      channel = await pusher.subscribe(channelName);
      channel.bind(`notification`, async () => {
        await getCount(channelName);
        window.dispatchEvent(new CustomEvent(`messageReceived`));
      });
    };
    const pusherInit = async () => {
      const existingChannel = await pusher.channel(`${user.uuid}-${auth.id}`);
      channelName = existingChannel
        ? `${user.uuid}-${auth.id}`
        : `${auth.id}-${user.uuid}`;
      channelConnect(channelName);
      getCount(channelName);
    };
    pusherInit();
    return () => {
      if (channel) {
        channel.unbind("notification");
      }
      if (channelName) {
        pusher.unsubscribe(channelName);
      }
    };
  }, [user, auth.id, pusher, currentChat]);

  return (
    <ListItemButton
      onClick={async () => {
        setCurrentChat(user);
      }}
      selected={currentChat?.id === user.id}
      sx={{ overflow: "hidden", pt: "6px", pb: "6px" }}
    >
      <ListItemIcon>
        <Badge
          color="success"
          variant="dot"
          overlap="circular"
          badgeContent=" "
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          invisible={!checkOnline(user.uuid)}
        >
          <Avatar
            sx={{
              width: 36,
              height: 36,
              fontSize: 12,
              fontWeight: 600,
              bgcolor: color.current,
              color: "#212121",
            }}
          >
            {getUserInitials(user.name)}
          </Avatar>
        </Badge>
      </ListItemIcon>
      <Badge
        sx={{
          ".MuiBadge-badge": {
            minWidth: 14,
            height: 14,
            fontSize: "0.7rem",
            mt: 0.5,
          },
        }}
        color="success"
        badgeContent={count}
        invisible={!count}
      >
        <ListItemText
          primary={`${user.name}${me ? " (You)" : ""}`}
          sx={{ pt: 0.5 }}
        />
      </Badge>
    </ListItemButton>
  );
}
