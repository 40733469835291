import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Download from "@mui/icons-material/Download";

import config from "../config";
import { checkArray } from "../utils/checkArray";
import { EntityApi, DocumentVaultAPI, useAuth } from "@unity/components";

export default function FilesScreen({ chatId }) {
  const { checkPermission } = useAuth();
  const canDownloadDocument = checkPermission(`download_document_user`);

  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await EntityApi.getEntityByType(
          config.documentIdEntityTypeId,
          chatId
        );
        if (res && res.success && checkArray(res.data.data)) {
          let localDocs = [];
          const resPromises = res.data.data[0].documentIds.map((el) =>
            DocumentVaultAPI.getDocument(el)
          );
          Promise.all(resPromises).then((res) => {
            res &&
              res.map((el) => {
                if (el && el.success) {
                  localDocs.push(el.data);
                }
              });
            setDocuments(localDocs);
          });
        }
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, [chatId]);

  const generateLink = async (doc_id) => {
    const res = await DocumentVaultAPI.generateTempLink(doc_id);
    window.open(res.data, "_blank");
  };

  return (
    <TableContainer
      sx={{ maxWidth: window.innerWidth - 370, overflow: "auto" }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>File Name</TableCell>
            <TableCell>Added By</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Size</TableCell>
            <TableCell>Created</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {documents.map((el) => (
            <TableRow key={el.id}>
              <TableCell>{el.name}</TableCell>
              <TableCell>{el.created_by_name}</TableCell>
              <TableCell>{el.raw_type}</TableCell>
              <TableCell>{el.size}</TableCell>
              <TableCell>{el.created_at}</TableCell>
              <TableCell sx={{ position: "sticky", right: 0 }}>
                {canDownloadDocument && (
                  <IconButton
                    color="primary"
                    onClick={() => generateLink(el.id)}
                  >
                    <Download />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
