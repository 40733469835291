import { Divider, Stack, Box } from "@mui/material";
import React, { useEffect, useState } from "react";

import UserList from "./userList";
import GroupList from "./groups/groupList";
import SearchBox from "./searchBox";
import { useWindowSize } from "../utils/useWindowSize";

export default function LeftPanel({ currentChat, setCurrentChat }) {
  const windowSize = useWindowSize();

  const [searchTerm, setSearchTerm] = useState("");
  const [itemHeight, setItemHeight] = useState(100);
  const [userHeight, setUserHeight] = useState(100);
  const [groupHeight, setGroupHeight] = useState(100);
  const [userLength, setUserLength] = useState(0);
  const [groupLength, setGroupLength] = useState(0);
  const [userOverflow, setUserOverflow] = useState(false);
  const [groupOverflow, setGroupOverflow] = useState(false);

  useEffect(() => {
    const getHeights = () => {
      const availableHeight = windowSize.height - 75;
      const itemHeight = 50;
      const offset = 130;
      setItemHeight(availableHeight);
      if (userOverflow && groupOverflow) {
        setUserHeight(availableHeight / 2 - 59);
        setGroupHeight(availableHeight / 2 - 59);
      } else if (userOverflow) {
        setUserHeight(availableHeight - groupLength * itemHeight);
        setGroupHeight(groupLength * itemHeight);
      } else if (groupOverflow) {
        setGroupHeight(availableHeight - userLength * itemHeight - offset);
        setUserHeight(userLength * itemHeight);
      } else {
        setUserHeight(userLength * itemHeight);
        setGroupHeight(groupLength * itemHeight);
      }
    };
    getHeights();
  }, [windowSize.height, userOverflow, groupOverflow, userLength, groupLength]);

  return (
    <Box
      sx={{
        bgcolor: "#fafafa",
        height: itemHeight,
        p: 1,
        borderRight: 1,
        borderColor: "#bdbdbd",
      }}
    >
      <Stack spacing={2}>
        <SearchBox value={searchTerm} setValue={setSearchTerm} />
        <Divider />
        <Box
          className="tour-messaginguserlist"
          sx={{ maxHeight: userHeight, overflow: "auto" }}
        >
          <UserList
            searchTerm={searchTerm}
            currentChat={currentChat}
            setCurrentChat={setCurrentChat}
            setUserLength={setUserLength}
            setUserOverflow={setUserOverflow}
            availableHeight={itemHeight}
          />
        </Box>
        <Divider />
        <Box
          className="tour-messaginggrouplist"
          sx={{ maxHeight: groupHeight, overflowY: "auto" }}
        >
          <GroupList
            searchTerm={searchTerm}
            currentChat={currentChat}
            setCurrentChat={setCurrentChat}
            setGroupLength={setGroupLength}
            setGroupOverflow={setGroupOverflow}
            availableHeight={itemHeight}
          />
        </Box>
      </Stack>
    </Box>
  );
}
