const apiBase =
  process.env.NODE_ENV === "production"
    ? window.location.hostname
    : "http://localhost:8080";

module.exports = {
  // ports
  serverPort: 8080,
  //This app url
  appURL: apiBase,
  groupEntityTypeId: "6571f6097e4234a569055893",
  groupMemberEntityTypeId: "6571f6047e4234a569055892",
  pollEntityTypeId: "6571f535e772c38ccd0c02e2",
  pollResultEntityTypeId: "6571f5c95fc75308600f2f82",
  documentIdEntityTypeId: "659537138e65004cee044732",
  resourceName: "messaging",
  defaultRole: "manager",
};
