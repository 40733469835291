import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useState } from "react";

import { EntityApi, useAuth } from "@unity/components";
import config from "../../config";
import { useApiLoader } from "../../services/apiLoaderContext";

export default function GroupMenu({
  open,
  onClose,
  group,
  onManage,
  setSnackbar,
}) {
  const { auth, checkPermission } = useAuth();
  const { setLoading } = useApiLoader();
  const isOwner = group?.created_by === auth.id;
  const canDeleteGroup = checkPermission(`delete_group_user`);
  const canUpdateGroup = checkPermission(`update_group_user`);

  const [dialogOpen, setDialogOpen] = useState(null);

  const handleLeave = async () => {
    try {
      setLoading(true);
      //put on entity, filter out current user from group.members,
      const payload = {
        type_id: config.groupMemberEntityTypeId,
        queries: [
          {
            AND: ["groupId", group?._id],
          },
          {
            AND: ["userId", auth.id],
          },
        ],
      };
      const res = await EntityApi.queryEntityWithinType(payload);
      if (res && res.success) {
        const docId = res.data.data[0]._id;
        const res1 = await EntityApi.deleteEntity(
          docId,
          config.groupMemberEntityTypeId
        );
        if (res1 && res1.success) {
          setSnackbar({ open: true, message: "success", success: true });
        } else {
          setSnackbar({
            open: true,
            message: "There was an error with your request",
            success: false,
          });
        }
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      setSnackbar({
        open: true,
        message: "There was an error with your request",
        success: false,
      });
    }
  };

  const handleDelete = async () => {
    try {
      const res = await EntityApi.deleteEntity(
        group?._id,
        config.groupEntityTypeId
      );
      if (res && res.success) {
        setSnackbar({ open: true, message: "success", success: true });
      } else {
        setSnackbar({
          open: true,
          message: "There was an error with your request",
          success: false,
        });
      }
    } catch (err) {
      console.log(err);
      setSnackbar({
        open: true,
        message: "There was an error with your request",
        success: false,
      });
    }
  };

  return (
    <>
      <Menu open={Boolean(open)} anchorEl={open} onClose={onClose}>
        <MenuItem onClick={() => setDialogOpen("Leave")} disabled={isOwner}>
          Leave group
        </MenuItem>
        {canUpdateGroup && (
          <MenuItem onClick={onManage} disabled={!isOwner}>
            Manage group
          </MenuItem>
        )}
        {canDeleteGroup && (
          <MenuItem onClick={() => setDialogOpen("Delete")} disabled={!isOwner}>
            Delete group
          </MenuItem>
        )}
      </Menu>
      <Dialog
        open={Boolean(dialogOpen)}
        onClose={() => setDialogOpen(null)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>
          {dialogOpen === "Leave" ? "Leave group?" : "Delete group?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setDialogOpen(null)}>Cancel</Button>
          <Button
            variant="contained"
            onClick={dialogOpen === "Leave" ? handleLeave : handleDelete}
          >
            {dialogOpen}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
